<template>
  <div class="amui-chips">
    <amui-chip
      v-for="d in data"
      :key="d.value"
      :label="d.label"
      selectable
      :selected="isSelected(d.value)"
      :disabled="typeof d.disabled === 'boolean' ? d.disabled : false"
      @update:selected="
        isSelected => onSelect(d.value, isSelected, !isSelected)
      "
    />
  </div>
</template>

<script>
import { AmuiChip } from './../chip'

export default {
  name: 'AmuiChips',

  components: {
    AmuiChip
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    /**
     * data for chips
     * [
     *   {
     *     label: String,
     *     value: Boolean, Number, String,
     *     disabled: Boolean (optional)
     *   },
     *   ...
     * ]
     */
    data: {
      type: Array,
      required: false,
      default: () => []
    },

    type: {
      type: String,
      required: true,
      validator(value) {
        return ['checkbox', 'radio'].includes(value)
      }
    },

    value: {
      type: [Number, String, Array, Boolean],
      required: false,
      default: null
    },

    // used for "radio" mode
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    isTypeOfCheckbox() {
      return (
        this.type === 'checkbox' &&
        (this.value === null || Array.isArray(this.value))
      )
    },
    isTypeOfRadio() {
      return (
        this.type === 'radio' &&
        (this.value === null ||
          ['number', 'string'].includes(typeof this.value))
      )
    }
  },

  methods: {
    isSelected(value) {
      let selected = false

      if (this.isTypeOfCheckbox) {
        selected = this.value.includes(value)
      } else if (this.isTypeOfRadio) {
        selected = this.value === value
      }

      return selected
    },
    onSelect(value, isSelected, isSelectedOld) {
      let newValue

      if (this.isTypeOfCheckbox) {
        if (isSelected) {
          newValue = this.value.concat([value])
        } else {
          newValue = this.value.filter(v => v !== value)
        }
      } else if (this.isTypeOfRadio) {
        if (this.required) {
          newValue = isSelectedOld === false ? value : this.value
        } else {
          newValue = isSelected ? value : ''
        }
      }

      if (newValue !== this.value) {
        this.$emit('change', newValue)
      }
    }
  }
}
</script>
