<template>
  <div class="am-search-hybrid-footer">
    <div class="am-search-hybrid-footer__inner">
      <div class="am-search-hybrid-footer__content">
        <amui-icon
          name="favorite"
          class="am-search-hybrid-footer__icon"
          @click.native="$emit('show-favorites')"
        >
          <amui-badge :label="favorites" />
        </amui-icon>
        <amui-button
          icon="tune-simple"
          :label="$t('sh.footer.search')"
          type="secondary"
          @click.native="$emit('toggle-filter-panel')"
        ></amui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiButton } from '@/../ui/components/button'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiBadge } from '@/../ui/components/badge'

export default {
  name: 'AmSearchHybridFooter',

  components: {
    AmuiButton,
    AmuiIcon,
    AmuiBadge
  },

  props: {
    favorites: {
      type: Number,
      required: false,
      default: 0
    }
  }
}
</script>

<style></style>
