<template>
  <div class="amui-chip-cloud">
    <template v-for="(tag, i) in data">
      <amui-chip
        :key="tag.value + '-' + i"
        :label="tag.label"
        removable
        @removed="$emit('remove', tag.value)"
        :class="{
          'amui-chip-cloud__chip': true,
          'amui-chip-cloud__chip--optional-visible-s':
            !showAll && i > maxVisibleItemsS - 1
        }"
      />
    </template>
    <amui-chip
      :label="showAll ? showLessLabel : showMoreLabel"
      type="primary"
      clickable
      :icon="showAll ? 'less-box' : 'more-box'"
      :class="{
        'amui-chip-cloud__reducer-toggle': true,
        'amui-chip-cloud__reducer-toggle--visible-s':
          data.length > maxVisibleItemsS
      }"
      @click="showAll = !showAll"
    />
    <slot />
  </div>
</template>

<script>
import { AmuiChip } from './../chip'

export default {
  name: 'AmuiChipCloud',

  components: {
    AmuiChip
  },

  props: {
    /**
     * data for chips
     * [
     *   {
     *     label: String,
     *     value: Boolean, Number, String,
     *   },
     *   ...
     * ]
     */
    data: {
      type: Array,
      required: false,
      default: () => []
    },

    showMoreLabel: {
      type: String,
      required: true
    },

    showLessLabel: {
      type: String,
      required: true
    },

    maxVisibleItemsS: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      showAll: false
    }
  }
}
</script>
