<template>
  <div
    class="amui-range-slider"
    :class="{ 'amui-range-slider--responsive': viewport === null }"
  >
    <div class="amui-range-slider__header">
      <div v-if="max" class="amui-range-slider__value">
        <slot name="from" :value="value[0]">
          {{ labelFrom }}
          <span class="amui-range-slider__value--black">{{ value[0] }}</span>
          {{ unit }}
        </slot>
      </div>
      <div v-if="max" class="amui-range-slider__value">
        <slot name="to" :value="value[1]">
          {{ labelTo }}
          <span class="amui-range-slider__value--black">{{ value[1] }}</span>
          {{ unit }}
        </slot>
      </div>
    </div>
    <div class="amui-range-slider__body" @click="onClick">
      <div class="amui-range-slider--clickable"></div>
      <div class="amui-range-slider__track-container">
        <div
          class="amui-range-slider__track"
          :style="tracking"
          :class="thumbMoving"
        ></div>
      </div>
      <div
        class="amui-range-slider__thumb-container amui-range-slider__thumb-container-0"
        :style="setPositionMin"
        @mousedown="startDrag($event, 0)"
        @touchstart="startDrag($event, 0)"
        :class="thumbMoving"
      >
        <amui-icon
          name="slider-thumb"
          size="sm"
          :viewport="viewport"
          class="amui-range-slider__thumb"
        />
        <div class="amui-range-slider__focus-ring"></div>
      </div>
      <div
        class="amui-range-slider__thumb-container amui-range-slider__thumb-container-1"
        :style="setPositionMax"
        @mousedown="startDrag($event, 1)"
        @touchstart="startDrag($event, 1)"
        :class="thumbMoving"
      >
        <amui-icon
          name="slider-thumb"
          size="sm"
          :viewport="viewport"
          class="amui-range-slider__thumb"
        />
        <div class="amui-range-slider__focus-ring"></div>
      </div>
    </div>
  </div>
</template>

<script>
const AMUI_VALID_UNITS = ['€', 'km', 'Monate'] // keep order
import { AmuiIcon } from './../icon'

export default {
  name: 'AmuiRangeSlider',

  components: {
    AmuiIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    min: {
      type: Number,
      required: true
    },

    max: {
      type: Number,
      required: true
    },

    step: {
      type: Number,
      required: true
    },

    // debounce in milliseconds, can be used for change event / v-model
    debounce: {
      type: Number,
      required: false,
      default: 0
    },

    // emit change event only at the end of dragging/click, or during drag
    immediateEmit: {
      type: Boolean,
      required: false,
      default: false
    },

    value: {
      type: Array,
      required: false,
      default: () => [0, 0]
    },

    labelFrom: {
      type: String,
      required: false,
      default: null
    },

    labelTo: {
      type: String,
      required: false,
      default: null
    },

    unit: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return AMUI_VALID_UNITS.includes(value)
      }
    },

    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    }
  },

  data: () => ({
    dragging: false,
    currentMinX: null,
    currentMaxX: null,
    initialMinX: 0,
    initialMaxX: 0,
    width: 0,
    currentMinValue: 0,
    currentMaxValue: 100,
    index: 0,
    sliderMin: 0,
    sliderValue: [0, 0],
    changeTimeout: null
  }),

  computed: {
    setPositionMin() {
      return `left: ${this.currentMinValue}%`
    },

    setPositionMax() {
      return `left: ${this.currentMaxValue}%`
    },

    thumbMoving() {
      return this.dragging ? 'amui-range-slider__thumb--moving' : ''
    },

    tracking() {
      return `width: ${this.currentMaxValue - this.currentMinValue}%; left: ${
        this.currentMinValue
      }%`
    },
    sliderMax() {
      return this.max - this.min
    }
  },

  methods: {
    startDrag(e, ind) {
      this.dragging = true
      this.index = ind
      this.width = e.target.closest('.amui-range-slider__body').clientWidth
      const left = e.target
        .closest('.amui-range-slider')
        .querySelector('.amui-range-slider__thumb-container-' + this.index)
        .offsetLeft

      switch (ind) {
        case 0:
          if (e.type === 'touchstart') {
            this.initialMinX = e.touches[0].screenX - left
          } else {
            this.initialMinX = e.screenX - left
          }
          break

        default:
          if (e.type === 'touchstart') {
            this.initialMaxX = e.touches[0].screenX - left
          } else {
            this.initialMaxX = e.screenX - left
          }
          break
      }
    },

    stopDrag() {
      if (!this.dragging) return
      this.dragging = false
      setTimeout(() => {
        this.onChangeValue(true)
      }, 100)
    },

    onChangeValue(isEnd) {
      const currentValue =
        this.index === 0 ? this.currentMinValue : this.currentMaxValue
      let value
      if (this.step > 0) {
        value = (currentValue * (this.sliderMax - this.sliderMin)) / 100

        const remainder = value % this.step
        if (remainder > 0 && remainder >= this.step / 2) {
          value = value - remainder + this.step
        } else {
          value = value - remainder
        }
        if (isEnd) {
          if (this.index === 0) {
            this.currentMinValue =
              ((value + this.sliderMin) / (this.sliderMax - this.sliderMin)) *
              100
          } else {
            this.currentMaxValue =
              ((value + this.sliderMin) / (this.sliderMax - this.sliderMin)) *
              100
          }
        }
      } else {
        value = (currentValue * (this.sliderMax - this.sliderMin)) / 100
      }
      this.value[this.index] = value + this.min
      this.sliderValue[this.index] = value + this.slideMin

      if (this.immediateEmit || isEnd) {
        if (this.debounce > 0) {
          this.setDebounce(() => {
            this.$emit('change', this.value)
          }, this.debounce)(this)
        } else {
          this.$emit('change', this.value)
        }
      }
    },

    setDebounce(func, wait) {
      return function(context) {
        const args = arguments
        const later = function() {
          context.changeTimeout = null
          func.apply(context, args)
        }
        clearTimeout(context.changeTimeout)
        context.changeTimeout = setTimeout(later, wait)
      }
    },

    doDrag(e) {
      if (this.dragging) {
        switch (this.index) {
          case 0:
            if (e.type === 'touchmove') {
              this.currentMinX = e.touches[0].screenX - this.initialMinX
            } else {
              this.currentMinX = e.screenX - this.initialMinX
            }

            this.currentMinValue = (this.currentMinX / this.width) * 100
            if (this.currentMinValue < 0) {
              this.currentMinValue = 0
            }
            if (this.currentMinValue > 100) {
              this.currentMinValue = 100
            }
            if (this.currentMinValue > this.currentMaxValue) {
              this.index = 1
              const tmp_a = this.currentMaxValue
              this.currentMaxValue = this.currentMinValue
              this.currentMinValue = tmp_a
              this.currentMaxX = this.currentMinX
              this.initialMaxX = this.initialMinX
            }
            this.onChangeValue(false)
            break

          default:
            if (e.type === 'touchmove') {
              this.currentMaxX = e.touches[0].screenX - this.initialMaxX
            } else {
              this.currentMaxX = e.screenX - this.initialMaxX
            }
            this.currentMaxValue = (this.currentMaxX / this.width) * 100
            if (this.currentMaxValue < 0) {
              this.currentMaxValue = 0
            }
            if (this.currentMaxValue > 100) {
              this.currentMaxValue = 100
            }
            if (this.currentMaxValue < this.currentMinValue) {
              this.index = 0
              const tmp_a = this.currentMaxValue
              this.currentMaxValue = this.currentMinValue
              this.currentMinValue = tmp_a
              this.currentMinX = this.currentMaxX
              this.initialMinX = this.initialMaxX
            }
            this.onChangeValue(false)
            break
        }
      }
    },

    onClick(e) {
      if (!e.target.closest('.amui-range-slider__thumb-container')) {
        this.dragging = true
        this.width = e.target.closest('.amui-range-slider__body').clientWidth
        const middel = (this.currentMaxValue - this.currentMinValue) / 2
        const pos = (e.layerX / this.width) * 100
        if (pos > this.currentMaxValue) {
          this.currentMaxValue = (e.layerX / this.width) * 100
          this.index = 1
        } else {
          if (pos < this.currentMinValue) {
            this.index = 0
            this.currentMinValue = (e.layerX / this.width) * 100
          } else {
            if (pos - this.currentMinValue < middel) {
              this.currentMinValue = (e.layerX / this.width) * 100
              this.index = 0
            } else {
              this.currentMaxValue = (e.layerX / this.width) * 100
              this.index = 1
            }
          }
        }
        this.stopDrag()
      }
    },
    initData() {
      if (this.value[0] > this.value[1]) {
        const tmp = this.value[0]
        this.value[0] = this.value[1]
        this.value[1] = tmp
        this.$emit('change', this.value)
      }
      this.sliderValue[0] = this.value[0] - this.min
      this.sliderValue[1] = this.value[1] - this.min
      this.currentMinValue =
        (this.sliderValue[0] / (this.sliderMax - this.sliderMin)) * 100
      this.currentMaxValue =
        (this.sliderValue[1] / (this.sliderMax - this.sliderMin)) * 100
    }
  },

  mounted() {
    window.addEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchend', this.stopDrag)
    window.addEventListener('mousemove', this.doDrag)
    window.addEventListener('touchmove', this.doDrag)

    this.initData()
  },

  watch: {
    min() {
      this.initData()
    },
    max() {
      this.initData()
    },
    step() {
      this.initData()
    },
    value() {
      this.initData()
    }
  }
}
</script>
