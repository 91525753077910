import { formatCount } from '../../../../../utils/formatter'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('core', ['companyName']),
    seoTitle() {
      let title = '<countVehicles> Fahrzeuge bei <companyName>'

      return this.replaceTags(title)
    },
    seoDescription() {
      let description =
        'Fahrzeugmarkt ★ Große Auswahl ★ Leasing und Finanzierung ★ ' +
        'Top-Angebote für Privat- & Firmenkunden ★ Überzeugen Sie sich! ★ <companyName>'
      return this.replaceTags(description)
    }
  },
  methods: {
    replaceTags: function(text) {
      text = text.replace('<countVehicles>', formatCount(this.total))
      text = text.replace('<companyName>', this.companyName)

      return text
    }
  }
}
