// only vue related stuff goes in here, otherwise, use the global util folder for generic js functions
const computedSetterForChipsModel = (
  flagDefintionList,
  attributeName = 'params'
) => {
  return function(values) {
    const resolvedFlagDefintionList =
      typeof flagDefintionList === 'function'
        ? flagDefintionList(this)
        : flagDefintionList

    resolvedFlagDefintionList.forEach(value => {
      const parsedValues = values.map(v => JSON.parse(v))
      const found =
        parsedValues.find(
          pv => pv.key === value.key && pv.value === value.value
        ) !== undefined
      if (found) {
        // set as new param
        if (value.key === 'flags') {
          const found2 =
            this[attributeName][value.key].find(f => f.name === value.value) !==
            undefined
          if (!found2) {
            this[attributeName][value.key].push({
              name: value.value,
              value: true
            })
          }
        } else {
          if (!this[attributeName][value.key].includes(value.value)) {
            this[attributeName][value.key].push(value.value)
          }
        }
      } else {
        // reset param
        if (value.key === 'flags') {
          this[attributeName][value.key] = this[attributeName][
            value.key
          ].filter(p => p.name !== value.value)
        } else {
          this[attributeName][value.key] = this[attributeName][
            value.key
          ].filter(p => p !== value.value)
        }
      }
    })
  }
}

const computedGetterForChipsModel = (
  flagDefintionList,
  attributeName = 'params'
) => {
  return function() {
    const model = []

    const resolvedFlagDefintionList =
      typeof flagDefintionList === 'function'
        ? flagDefintionList(this)
        : flagDefintionList

    resolvedFlagDefintionList.forEach(value => {
      if (value.key === 'flags') {
        if (
          this[attributeName][value.key].find(f => f.name === value.value) !==
          undefined
        ) {
          model.push(JSON.stringify(value))
        }
      } else {
        if (this[attributeName][value.key].includes(value.value)) {
          model.push(JSON.stringify(value))
        }
      }
    })

    return model
  }
}

// this model should be used if the chips list has different sources, like normal params and also flags
export const computedPropertyForChipsModel = (
  flagDefintionList,
  attributeName = 'params'
) => ({
  set: computedSetterForChipsModel(flagDefintionList, attributeName),
  get: computedGetterForChipsModel(flagDefintionList, attributeName)
})

export const computedPropertyForSingleSelectModelBasedOnArray = (
  attributeKey,
  attributeName = 'params'
) => {
  return {
    set(value) {
      const newArray = [value]

      if (this[attributeName][attributeKey].length === 0) {
        newArray.push(value)
      }
      this[attributeName][attributeKey] = newArray
    },
    get() {
      return this[attributeName][attributeKey].length > 0
        ? this[attributeName][attributeKey][0]
        : null
    }
  }
}

export const computedPropertyForMultiSelectModelBasedOnArray = (
  attributeKey,
  attributeName = 'params'
) => {
  return {
    set(value) {
      const values = typeof value === 'string' ? value.split(',') : []
      const newArray = []

      if (this[attributeName][attributeKey].length === 0 || values.length > 0) {
        Object.assign(newArray, [], values)
      }

      this[attributeName][attributeKey] = newArray
    },
    get() {
      return this[attributeName][attributeKey].length > 0
        ? this[attributeName][attributeKey].join(',')
        : null
    }
  }
}

export default {
  computedPropertyForChipsModel,
  computedPropertyForSingleSelectModelBasedOnArray,
  computedPropertyForMultiSelectModelBasedOnArray
}
