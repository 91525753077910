<template>
  <div class="am-search-hybrid-category-selection">
    <div
      v-for="option in options"
      :key="option.value"
      class="am-search-hybrid-category-selection__item"
      :class="{
        'am-search-hybrid-category-selection__item--disabled': option.disabled,
        'am-search-hybrid-category-selection__item--active': value.includes(
          option.id
        )
      }"
      @click="toggle(option.id)"
    >
      <div class="am-search-hybrid-category-selection__item-icons">
        <amui-icon
          v-if="option.icon"
          :name="option.icon"
          class="am-search-hybrid-category-selection__item-vehicle-icon"
        />
        <amui-icon
          v-if="value.includes(option.id)"
          name="check-circle"
          class="am-search-hybrid-category-selection__item-active-icon"
        />
      </div>
      <div class="am-search-hybrid-category-selection__item-label">
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'

export default {
  components: {
    AmuiIcon
  },

  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  methods: {
    toggle(id) {
      let newValue = [...this.value]

      if (this.value.includes(id)) {
        newValue = newValue.filter(v => v !== id)
      } else {
        newValue.push(id)
      }

      this.$emit('change', newValue)
    }
  }
}
</script>
