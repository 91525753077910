var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amui-chip-cloud"},[_vm._l((_vm.data),function(tag,i){return [_c('amui-chip',{key:tag.value + '-' + i,class:{
        'amui-chip-cloud__chip': true,
        'amui-chip-cloud__chip--optional-visible-s':
          !_vm.showAll && i > _vm.maxVisibleItemsS - 1
      },attrs:{"label":tag.label,"removable":""},on:{"removed":function($event){return _vm.$emit('remove', tag.value)}}})]}),_c('amui-chip',{class:{
      'amui-chip-cloud__reducer-toggle': true,
      'amui-chip-cloud__reducer-toggle--visible-s':
        _vm.data.length > _vm.maxVisibleItemsS
    },attrs:{"label":_vm.showAll ? _vm.showLessLabel : _vm.showMoreLabel,"type":"primary","clickable":"","icon":_vm.showAll ? 'less-box' : 'more-box'},on:{"click":function($event){_vm.showAll = !_vm.showAll}}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }