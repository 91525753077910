<template>
  <div
    class="amui-sidebar-collapsable"
    :class="{ 'amui-sidebar-collapsable--expanded': value }"
  >
    <button @click="toggle">
      <span>{{ label }}</span>
      <hr />
      <amui-icon
        name="chevron-down"
        size="sm"
        viewport="s"
        class="amui-sidebar-collapsable__icon"
      />
    </button>
    <div class="amui-sidebar-collapsable__content-wrapper">
      <div><slot /></div>
    </div>
  </div>
</template>
<script>
import { AmuiIcon } from './../icon'

export default {
  name: 'AmuiSidebarCollapsable',

  components: {
    AmuiIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    toggle() {
      this.$emit('change', !this.value)
    }
  }
}
</script>
