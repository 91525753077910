import { mapGetters } from 'vuex'
import { getSearchFormStructureByVehicleClass } from '@/shared/definitions/search'
import structuredClone from '@ungap/structured-clone'

export default {
  computed: {
    ...mapGetters('searchHybrid', ['getInitialSearchParams']),
    flattenSearchFormStructure() {
      const values = []

      const searchFormStructure = getSearchFormStructureByVehicleClass(
        this.params.vehicleClass,
        this.isSidebar || false
      )

      Object.keys(searchFormStructure).forEach(groupKey => {
        searchFormStructure[groupKey].forEach(field => {
          values.push(typeof field === 'string' ? { key: field } : field)
        })
      })

      return values
    },
    currentSearchTags() {
      let tags = []
      if (this.params !== null) {
        const searchFormStructure = getSearchFormStructureByVehicleClass(
          this.params.vehicleClass,
          this.isSidebar || false
        )
        if (searchFormStructure) {
          Object.keys(
            getSearchFormStructureByVehicleClass(
              this.params.vehicleClass,
              this.isSidebar || false
            )
          ).forEach(groupKey => {
            tags = tags.concat(this.currentSearchTagsByGroup[groupKey])
          })
        }
      }

      return tags
    },
    currentSearchTagsByGroup() {
      const groups = {}
      const searchFormStructure = getSearchFormStructureByVehicleClass(
        this.params.vehicleClass,
        this.isSidebar || false
      )

      Object.keys(searchFormStructure).forEach(groupKey => {
        const tags = []
        searchFormStructure[groupKey].forEach(d => {
          let param

          let options = {
            translation: true,
            unit: '', // TODO: why string per default?
            format: null,
            dependency: null
          }

          if (typeof d === 'object' && d.key) {
            param = d.key
            options.translation =
              typeof d.translation === 'boolean'
                ? d.translation
                : options.translation
            options.unit = d.unit ? ' ' + d.unit : ''
            options.format = d.format || null
            options.dependency = d.dependency || null
          } else {
            param = d
          }

          const translationPrefix =
            options.dependency === 'vehicleClass'
              ? 'vehicleClass.' + this.params.vehicleClass[0] + '.'
              : 'vehicle.'

          const value = this.params[param]
          // params that are initially defined with null
          if (typeof value === 'number') {
            tags.push({
              label:
                (options.translation
                  ? this.$t(translationPrefix + param) + ' ' + value
                  : value) + options.unit,
              value: JSON.stringify({
                key: param,
                value
              })
            })
          }
          if (typeof value === 'string') {
            const label = this.$t(translationPrefix + param)
            tags.push({
              label:
                (options.translation
                  ? (label ? label + ' ' : '') +
                    this.$t(translationPrefix + param + value)
                  : value) + options.unit,
              value: JSON.stringify({
                key: param,
                value
              })
            })
          }
          if (typeof value === 'boolean') {
            tags.push({
              label:
                (options.translation
                  ? this.$t(translationPrefix + param + value)
                  : value) + options.unit,
              value: JSON.stringify({
                key: param,
                value
              })
            })
          }

          // params that are initially defined with []
          else if (Array.isArray(value)) {
            if (typeof d.value === 'string') {
              // if a value attribute is set, this means show only a chip when the given value is inside of array
              if (param === 'flags') {
                // special treatment for flags because they are saved in some other format and translation key
                if (value.find(v => v.name === d.value) !== undefined) {
                  tags.push({
                    label: options.translation
                      ? this.$t(translationPrefix + d.value)
                      : d.value,
                    value: JSON.stringify({
                      key: param,
                      value: d.value
                    })
                  })
                }
              } else {
                if (value.includes(d.value)) {
                  tags.push({
                    label: options.translation
                      ? this.$t(translationPrefix + param + d.value)
                      : d.value,
                    value: JSON.stringify({
                      key: param,
                      value: d.value
                    })
                  })
                }
              }
            } else if (d.range) {
              const firstValue = String(value).split(',')[0]
              firstValue &&
                tags.push({
                  label:
                    (options.translation
                      ? this.$t(translationPrefix + param) + ' ab '
                      : null) + this.$t(translationPrefix + param + firstValue),
                  value: JSON.stringify({
                    key: param,
                    value
                  })
                })
            } else {
              value.forEach(v => {
                tags.push({
                  label: options.translation
                    ? this.$t(translationPrefix + param + v)
                    : v,
                  value: JSON.stringify({
                    key: param,
                    value: v
                  })
                })
              })
            }
          }

          // params that are initially defined with { min: null, max: null }
          else if (
            value instanceof Object &&
            (value.min !== undefined || value.max !== undefined)
          ) {
            const min = value.min
              ? options.format
                ? options.format(value.min)
                : value.min
              : null
            const max = value.max
              ? options.format
                ? options.format(value.max)
                : value.max
              : null

            if (min && max) {
              tags.push({
                label:
                  (options.translation &&
                    this.$t(translationPrefix + param) + ' ') +
                  min +
                  '–' +
                  max +
                  options.unit,
                value: JSON.stringify({
                  key: param,
                  value: 'both'
                })
              })
            } else {
              min &&
                tags.push({
                  label:
                    (options.translation &&
                      this.$t(translationPrefix + param) + ' ') +
                    ' > ' +
                    min +
                    options.unit,
                  value: JSON.stringify({
                    key: param,
                    value: 'min'
                  })
                })
              max &&
                tags.push({
                  label:
                    (options.translation &&
                      this.$t(translationPrefix + param) + ' ') +
                    '< ' +
                    max +
                    options.unit,
                  value: JSON.stringify({
                    key: param,
                    value: 'max'
                  })
                })
            }
          }
        })
        groups[groupKey] = tags
      })

      return groups
    }
  },
  methods: {
    getSearchTagsByGroup(groupKey) {
      let tags = []
      if (Array.isArray(this.currentSearchTagsByGroup[groupKey])) {
        tags = this.currentSearchTagsByGroup[groupKey]
      }

      return tags
    },
    removeSearchTag(value, paramsReference) {
      let params = structuredClone(paramsReference)

      const parsedValue = JSON.parse(value)

      const initialParams = this.getInitialSearchParams()
      const initialParam = initialParams[parsedValue.key]

      //////// delete related tags ////////////////////////////
      // TODO: Refactor that in a way that the params will be first set, and then only onced set, to avoid multiple requests
      const foundObject = this.flattenSearchFormStructure.find(
        e => e.key === parsedValue.key
      )

      if (Array.isArray(foundObject.relatedFields)) {
        foundObject.relatedFields.forEach(rf => {
          const found = this.currentSearchTags.find(t => {
            const parsedT = JSON.parse(t.value)
            return parsedT.key === rf
          })
          if (found) {
            params = this.removeSearchTag(found.value, params)
          }
        })
      }
      //////////////////////////////////////////////////////////

      if (initialParam === null) {
        params[parsedValue.key] = null
      } else if (Array.isArray(initialParam)) {
        if (parsedValue.key === 'flags') {
          params[parsedValue.key] = params[parsedValue.key].filter(
            v => v.name !== parsedValue.value
          )
        } else if (Array.isArray(parsedValue.value)) {
          params[parsedValue.key] = []
        } else {
          params[parsedValue.key] = params[parsedValue.key].filter(
            v => v !== parsedValue.value
          )
        }
      } else if (typeof initialParam === 'object') {
        /*
        const both = parsedValue.value === 'both' // TODO why "both"?
        const min = parsedValue.value === 'min'
        const max = parsedValue.value === 'max'

        if (both || min) {
          params[parsedValue.key].min = initialParam.min
        }
        if (both || max) {
          params[parsedValue.key].max = initialParam.max
        }
        */
        params[parsedValue.key] = initialParam
      }

      return params
    }
  }
}
